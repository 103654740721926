import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const classes = {
  title: 'text-lg font-bold',
  link: 'underline',
};

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <h1 className={classes.title}>❌ 404 | Not Found</h1>
    <p>
      <bold>👋 Hey there!</bold> You found a road that doesn't lead anywhere! {' '}
      <Link className={classes.link} to="/">
        🏡 Return Home
      </Link>
      .
    </p>
  </Layout>
);

export default NotFoundPage;
